@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

// #090D14 <- gray-950
// #111827 <- gray-900
// #374151 <- gray-700
// #4B5563 <- gray-600
// #9CA3AF <- gray-400
// #F3F4F6 <- gray-100
// #FFFFFF <- white

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

:root {
  --foreground: #d1d5db;
  --foreground-2: #ffffff;
  --background-1: #111827;
  --background-2: rgb(3, 7, 18);

  --headline-color: #ffffff;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground: #d1d5db;
    --foreground-2: #ffffff;
    --background-1: #111827;
    --background-2: rgb(3, 7, 18);

    --headline-color: #ffffff;
  }
}

body {
  color: var(--foreground-2);
  background: var(--background-2);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--headline-color);
}

header {
  -webkit-transition: -webkit-transform 150ms ease-in-out;
  transition: -webkit-transform 150ms ease-in-out;
  -o-transition: transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out;
  transition: transform 150ms ease-in-out, -webkit-transform 150ms ease-in-out;

  .links-area {
    a,
    span {
      position: relative;

      &.active {
        &::after {
          content: '';
          width: 12px;
          height: 4px;
          background-color: #0ea5e9;
          border-radius: 4px;
          display: inline-block;
          position: absolute;
          bottom: -10px;
          left: 0;
        }
      }
    }
  }

  &.is-hidden {
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.contact-us-page {
  input,
  textarea {
    border-radius: 6px;
    border: 1px solid rgba(209, 213, 219, 0.1);
    background: #1f2937;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  }
}

.about-us-page .sec-1 {
  .container {
    &::before {
      content: '';
      width: 350px;
      height: 1200px;

      background: #1f2937;
      opacity: 0.3;
      z-index: -2;
      display: block;
      position: absolute;
      right: 0;
      top: 0;

      @media (max-width: 991px) {
        display: none;
      }
    }
  }
}
